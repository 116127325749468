import fenixTheme from '@pedidosya/web-fenix/theme';
import { fenixColors } from './colors';
import { fenixSpaces } from './spaces';
import location from './modules/location';
import { DefaultTheme } from 'styled-components';

const hybridTheme = {
  ...fenixTheme,
  location,
  fenix: {
    colors: fenixColors,
    spaces: fenixSpaces,
  },
};

export default hybridTheme as DefaultTheme;
