import getScreenWidth from '@commons/utils/screen/getScreenWidth';
import { isServer } from '@commons/utils';
import { MOBILE, TABLET, DESKTOP } from './devices';
import isMobile from './isMobile';
import isTablet from './isTablet';
import isDesktop from './isDesktop';

const getDevice = () => {
  if (isServer()) return null;

  const width = getScreenWidth();

  let deviceType;

  if (isMobile(width)) deviceType = MOBILE;

  if (isTablet(width)) deviceType = TABLET;

  if (isDesktop(width)) deviceType = DESKTOP;

  return deviceType;
};

export default getDevice;
