import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, createContext } from 'react';
import axios from 'axios';
import loadable from '@loadable/component';
import pkg from '../../../package.json';

const Error = loadable(() => import('@pedidosya/web-fenix/pages/Error'));
const OverlaySpinner = loadable(() => import('@app/components/OverlaySpinner'), {
  fallback: <>Cargando...</>,
});

const PublicEnvContext = createContext();

export const getPublicEnv = async () => {
  const { data } = await axios.get(`/api/${pkg.name}/env`);
  return data;
};

export const usePublicEnv = () => {
  return useContext(PublicEnvContext);
};

export const PublicEnvProvider = ({ children, context, getPublicEnv = () => 0 }) => {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (publicEnv) return;
    getPublicEnv().then(setPublicEnv).catch(setError);
  }, [getPublicEnv, publicEnv]);

  if (error) return <Error />;

  if (!publicEnv) return <OverlaySpinner />;

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
