import { HUAWEI_WEBVIEW } from '@commons/utils/device/devices';
import getDevice from '@commons/utils/device/getDevice';
import React, { createContext, useCallback, useEffect, useState } from 'react';

export const DeviceContext = createContext(null);

function useDevice(serverDevice: string) {
  const initialDevice = serverDevice || getDevice();
  const [device, changeDevice] = useState(initialDevice);

  const handleResize = useCallback(() => {
    const updatedDevice = getDevice();
    const shouldUpdateDevice = updatedDevice !== device;

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    if (shouldUpdateDevice) {
      changeDevice(updatedDevice);
      // updateFwfCurrentDevice(updatedDevice);
      // eslint-disable-next-line no-console
      console.log(`[DEVICE_TYPE_CHANGED]: from ${device} to ${updatedDevice}`);
    }
  }, [device]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return device;
}

type DeviceProviderProps = {
  children?: React.ReactNode;
  serverDevice?: string;
};

const DeviceProvider: React.FC<DeviceProviderProps> = ({ serverDevice, children }) => {
  const device = useDevice(serverDevice);

  return (
    <DeviceContext.Provider value={serverDevice === HUAWEI_WEBVIEW ? serverDevice : device}>
      {children}
    </DeviceContext.Provider>
  );
};

export default DeviceProvider;
