export const fenixSpaces = {
  space_0: '0',
  space_2: '2px',
  space_3: '3px',
  space_4: '4px',
  space_6: '6px',
  space_8: '8px',
  space_10: '10px',
  space_12: '12px',
  space_16: '16px',
  space_18: '18px',
  space_20: '20px',
  space_24: '24px',
  space_32: '32px',
  space_40: '40px',
};
