import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import theme from '@app/theme';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import { sentryHub } from '@app/sentry';
import packageInfo from '../package.json';
import { createIntlProvider, getMessages } from './contexts/IntlProvider';
import DeviceProvider from './contexts/DeviceProvider';
import {
  usePublicEnv,
  PublicEnvProvider,
  publicEnvShape,
  getPublicEnv,
} from './contexts/PublicEnv';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ModalStyles from './components/ResponsiveModal/styles';
import loadable from '@loadable/component';

const MyAddressesFlow = loadable(() =>
  import('@app/modules/location-v2/containers/MyAddressesFlow'),
);
const AddressForm = loadable(() => import('@location-v2/containers/AddressForm'));
const MapFlow = loadable(() => import('@app/modules/shared/containers/MapWithReverse'));
const LocationFlow = loadable(() => import('@location-v2/containers/LocationFlow'));

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const SentryProvider = ({ children }) => {
  const { userId, environment, country } = usePublicEnv();

  useEffect(() => {
    try {
      sentryHub.init({ dsn: SENTRY_DSN, environment });
      sentryHub.setUser({ id: userId });
      sentryHub.setTag('country', country.shortName);
      sentryHub.setTag('templateVersion', packageInfo.templateVersion);
    } catch (error) {
      console.error('Error initializing sentry hub', error.message);
    }
  }, [environment, userId]);

  return children;
};

const Root = ({ context, messages, name, flow = 'DEFAULT', tracker, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <PublicEnvProvider context={context} getPublicEnv={getPublicEnv}>
        <QueryClientProvider client={queryClient}>
          <SentryProvider>
            <DeviceProvider>
              <>
                <FenixFonts />
                <ModalStyles />
                <IntlProvider initialMessages={messages}>
                  {(() => {
                    switch (flow) {
                      case 'LOCATION':
                        return <LocationFlow {...props} />;
                      case 'LOCATION_HOME':
                        return <LocationFlow {...props} />;
                      case 'MY_ADDRESSES':
                        return <MyAddressesFlow {...props} />;
                      case 'ADDRESS_FORM':
                        return <AddressForm {...props} />;
                      default:
                        return <div>NO flow PROVIDED</div>;
                    }
                  })()}
                </IntlProvider>
              </>
            </DeviceProvider>
          </SentryProvider>
        </QueryClientProvider>
      </PublicEnvProvider>
    </ThemeProvider>
  );
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  tracker: PropTypes.shape({
    track: PropTypes.func,
  }),
};

export default Root;
