import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import pkg from '../package.json';
import Root from './root.component';
import { ErrorBoundary } from './ErrorBoundary';

const appId = `single-spa-application:${pkg.name}`;

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,

  domElementGetter: () => {
    let element = document.getElementById(appId);
    if (!element) {
      element = document.createElement('div');
      element.id = appId;
      document.body.appendChild(element);
    }
    return element;
  },
  errorBoundary: (error) => <ErrorBoundary error={error} />,
});

export const { bootstrap, update, mount, unmount } = lifecycles;
