export const fenixColors = {
  'color-red-80': '#7D0438',
  'color-red-70': '#B40443',
  'color-red-60': '#D4044A',
  'color-red-50': '#EA044E',
  'color-red-40': '#EC1D60',
  'color-red-30': '#EF437A',
  'color-red-20': '#F481A6',
  'color-red-10': '#FDE6ED',
  'color-navy-90': '#100423',
  'color-navy-80': '#393243',
  'color-navy-70': '#4C4359',
  'color-navy-60': '#70697A',
  'color-navy-50': '#878290',
  'color-navy-40': '#C3C0C8',
  'color-navy-30': '#DBDADE',
  'color-navy-20': '#E7E6E9',
  'color-navy-10': '#F3F2F4',
  'color-navy-0': '#FFFFFF',
  'color-blue-60': '#1641B5',
  'color-blue-50': '#004ADD',
  'color-blue-40': '#2656F7',
  'color-blue-30': '#92AAFB',
  'color-blue-20': '#C9D5FD',
  'color-blue-10': '#E9EEFE',
  'color-cyan-60': '#47839F',
  'color-cyan-50': '#5FBBD5',
  'color-cyan-40': '#72E6FF',
  'color-cyan-30': '#C1F9FE',
  'color-cyan-20': '#DCF8FF',
  'color-cyan-10': '#F1FCFF',
  'color-green-60': '#1C8663',
  'color-green-50': '#23A87C',
  'color-green-40': '#2DE1A0',
  'color-green-30': '#60F6C0',
  'color-green-20': '#CAF7E7',
  'color-green-10': '#E9F8F3',
  'color-darkorange-60': '#964B41',
  'color-darkorange-50': '#D16A4E',
  'color-darkorange-40': '#FF8159',
  'color-darkorange-30': '#FFC0AC',
  'color-darkorange-20': '#FFDFD5',
  'color-darkorange-10': '#FAF0ED',
  'color-orange-60': '#BF5703',
  'color-orange-50': '#EF6C01',
  'color-orange-40': '#FFB526',
  'color-orange-30': '#FFDA92',
  'color-orange-20': '#FFECC9',
  'color-orange-10': '#FCF5E9',
  'color-yellow-60': '#958C2A',
  'color-yellow-50': '#D3C73C',
  'color-yellow-40': '#F8EA46',
  'color-yellow-30': '#FBF4A2',
  'color-yellow-20': '#FDFAD1',
  'color-yellow-10': '#FEFDED',
  'color-pink-60': '#94044F',
  'color-pink-50': '#D20570',
  'color-pink-40': '#F70684',
  'color-pink-30': '#FB84C2',
  'color-pink-20': '#FDBFE0',
  'color-pink-10': '#FEE6F3',
};
