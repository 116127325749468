import { BrowserClient, Hub } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { PROD_ENVIRONMENT } from '@appscore/web-constants/environments';

let customHub = null;

const sentryHub = {
  init: ({ dsn, environment }) => {
    const isLive = environment === PROD_ENVIRONMENT;

    const client = new BrowserClient({
      dsn,
      environment,
      integrations: [
        new Integrations.BrowserTracing({
          _metricOptions: {
            _reportAllChanges: true,
          },
        }),
      ],
      release: `${APP_NAME}@${APP_VERSION}`,
      tracesSampleRate: isLive ? 0.1 : 1,
      sampleRate: isLive ? 0.1 : 1,
    });

    customHub = new Hub(client);
    return customHub;
  },
  captureMessage: (message) => {
    if (!customHub) return null;
    customHub.run((hub) => hub.captureMessage(message));
  },
  captureException: (ex) => {
    if (!customHub) return null;
    customHub.run((hub) => hub.captureException(ex));
  },
  setTag: (key, value) => {
    if (!customHub) {
      throw new Error('Cannot set tags before hub initialization');
    }
    customHub.setTag(key, value);
  },
  setUser: (user) => {
    if (!customHub) {
      throw new Error('Cannot set user before hub initialization');
    }
    customHub.setUser(user);
  },
};

Object.freeze(sentryHub);

export { sentryHub };
