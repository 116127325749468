import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import theme from '@app/theme';
import Error from '@pedidosya/web-fenix/pages/Error';

import { sentryHub } from '@app/sentry';

export const ErrorBoundary = ({ error, ...props }) => {
  if (error?.__sentry_captured__) {
    // Allow the hub to re-catch the error if the main sentry instance already caught it
    delete error.__sentry_captured__;
  }

  sentryHub.captureException(error);

  return (
    <ThemeProvider theme={theme}>
      <Error {...props} />
    </ThemeProvider>
  );
};

ErrorBoundary.propTypes = {
  error: PropTypes.instanceOf(Error),
  onClick: PropTypes.func,
  secondaryActionLabel: PropTypes.string,
  secondaryActionClick: PropTypes.func,
};
