const location = {
  suggestionsContainer: {
    margin: '12px 20px',
  },
  autocompleteAddress: {
    borderBottom: `1px solid rgb(218,224,229)`,
    padding: '20px 20px 20px 32px',
  },
  historyAddress: {
    borderBottom: `1px solid rgb(218,224,229)`,
    padding: '20px 0',
    iconPadding: '10px',
  },
  searchInputContainer: {
    padding: '0px 4% 4%',
    elevation: `0 15px 20px -19px rgba(16, 4, 35, 0.4)`,
  },
  backButton: {
    backgroundColor: '#fff',
    shadow: '0 6px 16px -8px rgba(16, 4, 35, 0.75)',
  },
  footer: {
    topbar: {
      backgroundColor: '#DBDADE',
    },
  },
};

export default location;
