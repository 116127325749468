import { createGlobalStyle } from 'styled-components';

const ModalStyles = createGlobalStyle`
.web-location-modal-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.web-location-modal-overlay {
  background: rgba(65,61,75,.75) !important;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.web-location-modal-container {
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Used to trick the browser to center the modal content properly  */
.web-location-modal-containerCenter:after {
  width: 0;
  height: 100%;
  content: '';
  display: inline-block;
  vertical-align: middle;
}

.web-location-modal-modal {
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
}

.web-location-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}

@keyframes react-responsive-modal-overlay-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes react-responsive-modal-overlay-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes react-responsive-modal-modal-in {
  0% {
    transform: scale(0.96);
    opacity: 0;
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

@keyframes react-responsive-modal-modal-out {
  0% {
    transform: scale(100%);
    opacity: 1;
  }
  100% {
    transform: scale(0.96);
    opacity: 0;
  }
}

`;

export default ModalStyles;
